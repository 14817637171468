import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import useTranslation from "next-translate/useTranslation";
import { Box, FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react";
import { useAuthState } from "src/shared/contexts/AuthContext";
import { Button } from "src/shared/components/Button";
import SmileSharedProfileLayout from "../../../../shared/components/Layouts/SmileSharedProfileLayout";

interface SmileProfileChangeEmailScreenProps {
  onClose: () => void;
}

interface FormFieldValues {
  email: string;
}

const SmileProfileChangeEmailScreen = (props: SmileProfileChangeEmailScreenProps) => {
  const { t } = useTranslation();
  const { profile } = useAuthState();
  const formMethods = useForm<FormFieldValues>({
    mode: "onBlur",
    defaultValues: {
      email: profile?.email ?? "",
    },
  });
  const { errors, register } = formMethods;

  const handleSubmit = formMethods.handleSubmit(async (values) => {
    // TODO: handle
    console.log("values", values);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    props.onClose();
  });

  return (
    <SmileSharedProfileLayout title={"Change email"} onGotoBack={props.onClose}>
      <Box maxW={{ base: "unset", sm: "400px" }}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit}>
            <FormControl id="email" isInvalid={!!errors.email} isRequired>
              <FormLabel fontWeight="bold" fontSize="16px" lineHeight="20px" mt="50px">
                {t("smile:smileSignup_emailLabel")}
              </FormLabel>
              <Input
                variant="flushed"
                w="100%"
                name="email"
                type="email"
                ref={register({ required: t("smile:form_formRequiredField") })}
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>
            <Box mt={"88px"} display={"flex"} justifyContent={{ base: "center", sm: "unset" }}>
              <Button type="submit" isLoading={formMethods.formState.isSubmitting}>
                Update
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </SmileSharedProfileLayout>
  );
};

export default SmileProfileChangeEmailScreen;

import React, { useCallback, useState } from "react";
import SmileProfileHomeScreen from "./components/Screens/SmileProfileHomeScreen";
import SmileProfileChangeNameScreen from "./components/Screens/SmileProfileChangeNameScreen";
import SmileProfileChangeEmailScreen from "./components/Screens/SmileProfileChangeEmailScreen";
import SmileProfileChangePhoneScreen from "./components/Screens/SmileProfileChangePhoneScreen";
import SmileProfileChangePasswordScreen from "./components/Screens/SmileProfileChangePasswordScreen";

type Screens = "home" | "change-name" | "change-email" | "change-phone" | "change-password";

const SmileProfile = () => {
  const [activeScreen, setActiveScreen] = useState<Screens>("home");

  const handleSetHomeScreen = useCallback(() => {
    setActiveScreen("home");
  }, [setActiveScreen]);

  const handleSetChangeNameScreen = useCallback(() => {
    setActiveScreen("change-name");
  }, [setActiveScreen]);

  const handleSetChangeEmailScreen = useCallback(() => {
    setActiveScreen("change-email");
  }, [setActiveScreen]);

  const handleSetChangePhoneScreen = useCallback(() => {
    setActiveScreen("change-phone");
  }, [setActiveScreen]);

  const handleSetChangePasswordScreen = useCallback(() => {
    setActiveScreen("change-password");
  }, [setActiveScreen]);

  switch (activeScreen) {
    case "change-name":
      return <SmileProfileChangeNameScreen onClose={handleSetHomeScreen} />;

    case "change-email":
      return <SmileProfileChangeEmailScreen onClose={handleSetHomeScreen} />;

    case "change-phone":
      return <SmileProfileChangePhoneScreen onClose={handleSetHomeScreen} />;

    case "change-password":
      return <SmileProfileChangePasswordScreen onClose={handleSetHomeScreen} />;

    default:
      return (
        <SmileProfileHomeScreen
          onChangeName={handleSetChangeNameScreen}
          onChangeEmail={handleSetChangeEmailScreen}
          onChangePhone={handleSetChangePhoneScreen}
          onChangePassword={handleSetChangePasswordScreen}
        />
      );
  }
};

export default SmileProfile;

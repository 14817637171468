import React, { useState } from "react";
import useTranslation from "next-translate/useTranslation";
import SmileSharedProfileLayout from "src/modules/smile/shared/components/Layouts/SmileSharedProfileLayout";
import SmileChangePlanForm from "src/modules/smile/pages/Subscription/components/Forms/SmileChangePlanForm";
import SmilePayment from "src/modules/smile/pages/Subscription/components/Views/SmilePayment";
import SmilePaymentForm from "src/modules/smile/pages/Subscription/components/Forms/SmilePaymentForm";
import SmileCancellation from "src/modules/smile/pages/Subscription/components/Views/SmileCancellation";

const SmileSubscription = () => {
  const { t } = useTranslation();
  const [isPaymentFormVisible, setIsPaymentFormVisible] = useState(false);

  if (isPaymentFormVisible) {
    return (
      <SmileSharedProfileLayout
        title={t("smile:pixPayment_paymentModalTitle")}
        onGotoBack={() => setIsPaymentFormVisible(false)}
      >
        <SmilePaymentForm onComplete={() => setIsPaymentFormVisible(false)} />
      </SmileSharedProfileLayout>
    );
  }

  return (
    <SmileSharedProfileLayout title={t("smile:smilePlusHome_subscriptionTitle")}>
      <SmileChangePlanForm />
      <SmilePayment onEdit={() => setIsPaymentFormVisible(true)} />
      <SmileCancellation />
    </SmileSharedProfileLayout>
  );
};

export default SmileSubscription;

import React from "react";
import { useRouter } from "next/router";
import SmileProfile from "../Profile";
import SmileSubscription from "../Subscription";

const SmileHome = () => {
  const router = useRouter();

  const pageQuery = router.query.page || "home";
  if (pageQuery === "home") {
    return <SmileProfile />;
  }

  return (
    <>
      {pageQuery === "subscription" && <SmileSubscription />}
      {pageQuery === "profile" && <SmileProfile />}
    </>
  );
};

export default SmileHome;

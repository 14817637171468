import React, { useCallback, ReactNode } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import NextImage from "next/image";
import { useRouter } from "next/router";
import Router from "next-translate/Router";
import useTranslation from "next-translate/useTranslation";
import * as consts from "src/shared/consts";
import { useAuthDispatch, useAuthState } from "src/shared/contexts/AuthContext";
import { UDINI_HOME_ROUTE } from "src/utils/constants/routes";
import { APP_HOME_ROUTE, APP_SIGNIN_ROUTE, IS_HYBRIDGE } from "src/modules/smile/shared/consts";

interface SmileSharedProfileLayoutProps {
  title?: string;
  subtitle?: string;
  children: ReactNode;
  onGotoBack?: () => void;
}

const SmileSharedProfileLayout = (props: SmileSharedProfileLayoutProps) => {
  const { profile } = useAuthState();
  const { disconnect } = useAuthDispatch();

  const handleGotoHome = useCallback(() => {
    Router.pushI18n(UDINI_HOME_ROUTE);
  }, []);

  const handleGotoProfile = useCallback(() => {
    Router.pushI18n(`${APP_HOME_ROUTE}?page=profile`);
  }, []);

  const handleGotoSubscription = useCallback(() => {
    Router.pushI18n(`${APP_HOME_ROUTE}?page=subscription`);
  }, []);

  const handleDisconnect = useCallback(() => {
    disconnect();
    Router.pushI18n(IS_HYBRIDGE ? APP_SIGNIN_ROUTE : UDINI_HOME_ROUTE);
  }, [disconnect]);

  return (
    <Flex h={"100vh"} position={"relative"} zIndex={0} color={consts.COLOR_DARK_BLUE}>
      <Content title={props.title} subtitle={props.subtitle} onGotoBack={props.onGotoBack}>
        {props.children}
      </Content>
      <Sidebar
        title={`${profile?.infos?.firstName} ${profile?.infos?.lastName}`}
        onGotoHome={handleGotoHome}
        onGotoProfile={handleGotoProfile}
        onGotoSubscription={handleGotoSubscription}
        onDisconnect={handleDisconnect}
      />
      <TopBar onGotoHome={handleGotoHome} />
      <BottomBar
        onGotoProfile={handleGotoProfile}
        onGotoSubscription={handleGotoSubscription}
        onDisconnect={handleDisconnect}
      />
    </Flex>
  );
};

interface ContentProps {
  title?: string;
  subtitle?: string;
  children: ReactNode;
  onGotoBack?: () => void;
}

const Content = (props: ContentProps) => {
  return (
    <Box
      order={1}
      flex={6}
      overflowY={"auto"}
      bg={
        IS_HYBRIDGE
          ? "white"
          : "linear-gradient(180deg, #F1FEFF -0.63%, #EDF7FF 22.95%, #FDFDFF 48.11%, #ECF6FF 69.08%, #FFFCFE 85.85%, #E7F3FF 100%)"
      }
      px={{ base: "15px", md: "125px" }}
      py={{ base: "30px", md: "120px" }}
      mt={{ base: "60px", sm: "unset" }}
      mb={{ base: "72px", sm: "unset" }}
    >
      {props.onGotoBack && (
        <Box cursor={"pointer"} mb={"25px"} onClick={props.onGotoBack}>
          <NextImage src={"/svg/arrow_left.svg"} width={"16px"} height={"16px"} />
        </Box>
      )}
      {(props.title || props.subtitle) && (
        <Box mb={"40px"}>
          {props.title && (
            <Text
              fontFamily="Montserrat"
              fontWeight={{ base: "bold", md: "600" }}
              fontSize={{ base: "32px", md: "40px" }}
              lineHeight={"140%"}
              mb={props.subtitle ? { base: "8px", md: "11px" } : "unset"}
            >
              {props.title}
            </Text>
          )}
          {props.subtitle && (
            <Text fontFamily="Montserrat" fontSize={{ base: "12px", md: "17px" }} lineHeight={"150%"}>
              {props.subtitle}
            </Text>
          )}
        </Box>
      )}
      {props.children}
    </Box>
  );
};

interface SidebarProps {
  title: string;
  onGotoHome: () => void;
  onGotoProfile: () => void;
  onGotoSubscription: () => void;
  onDisconnect: () => void;
}

const Sidebar = (props: SidebarProps) => {
  const { t } = useTranslation();
  const activePage = useActivePage();
  return (
    <Box
      order={0}
      flex={4}
      display={{ base: "none", sm: "flex" }}
      flexDirection="column"
      bg={IS_HYBRIDGE ? consts.COLOR_RED : "linear-gradient(147.43deg, #FC2C54 -0.43%, #FFA7DC 50.31%, #FC2C54 97.38%)"}
      color={"white"}
      p={"50px"}
    >
      {IS_HYBRIDGE ? (
        <Box w={"169px"} mb={{ base: "80px", md: "140px" }} cursor={"pointer"} onClick={props.onGotoHome}>
          <NextImage src="/svg/hybridge-logo.svg" alt="Hybridge logo" width="232px" height="62px" priority />
        </Box>
      ) : (
        <Box w={"115px"} mb={{ base: "80px", md: "140px" }} cursor={"pointer"} onClick={props.onGotoHome}>
          <NextImage src="/svg/SmilePlusProductLogoWhite.svg" alt="Smile+ logo" width="150px" height="45px" priority />
        </Box>
      )}
      <Box flex={1} pl={{ base: 0, md: "60px" }} pr={{ base: "50px", md: "110px" }}>
        <Text fontFamily="Montserrat" fontWeight={"600"} fontSize={{ base: "24px", md: "32px" }} mb={"80px"}>
          {props.title}
        </Text>
        <Box>
          <Box mb={"40px"}>
            <SidebarItem
              icon={"/img/smile/webApp/menu/profile_icon.png"}
              text={t("smile:smilePlusHome_menuProfile")}
              isActive={activePage === "profile"}
              onClick={props.onGotoProfile}
            />
          </Box>
          <Box mb={"120px"}>
            <SidebarItem
              icon={"/img/smile/webApp/menu/subscription_icon.png"}
              text={t("smile:smilePlusHome_menuSubscription")}
              isActive={activePage === "subscription"}
              onClick={props.onGotoSubscription}
            />
          </Box>
          <Box>
            <SidebarItem
              icon={"/svg/smilePlusLogout.svg"}
              text={t("smile:smilePlusHome_menuDisconnect")}
              kind={"secondary"}
              onClick={props.onDisconnect}
            />
          </Box>
        </Box>
      </Box>
      <Box w={"95px"} cursor={"pointer"} onClick={props.onGotoHome}>
        <NextImage src="/svg/UdiniLogoWhite.svg" alt="Udini logo" width="150px" height="75px" priority />
      </Box>
    </Box>
  );
};

interface SidebarItemProps {
  icon: string;
  text: string;
  isActive?: boolean;
  kind?: "primary" | "secondary";
  onClick: () => void;
}

const SidebarItem = (props: SidebarItemProps) => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      cursor={"pointer"}
      _hover={{ textDecoration: "underline" }}
      onClick={props.onClick}
    >
      <Box display={"flex"} w={"20px"} mr={"25px"}>
        <NextImage src={props.icon} width={"20px"} height={"22px"} />
      </Box>
      <Text
        flex={1}
        fontFamily="Montserrat"
        fontWeight={props.isActive ? "700" : "600"}
        fontSize={props.kind === "secondary" ? "11px" : "14px"}
        lineHeight="140%"
        textTransform={"uppercase"}
      >
        {props.text}
      </Text>
    </Box>
  );
};

interface TopBarProps {
  onGotoHome: () => void;
}

const TopBar = (props: TopBarProps) => {
  return (
    <Box
      display={{ base: "flex", sm: "none" }}
      position={"absolute"}
      w={"100%"}
      h={"60px"}
      top={0}
      px={"15px"}
      boxShadow={"0px 4px 4px rgba(179, 179, 179, 0.05)"}
    >
      <Box display={"flex"} alignItems={"center"} cursor={"pointer"} onClick={props.onGotoHome}>
        <NextImage src={"/svg/SmilePlusProductLogo.svg"} width={"73px"} height={"20px"} />
      </Box>
      <Box flex={1}></Box>
      <Box display={"flex"} alignItems={"center"} cursor={"pointer"} onClick={props.onGotoHome}>
        <NextImage src={"/svg/UdiniLogoSmile.svg"} width={"66px"} height={"35px"} />
      </Box>
    </Box>
  );
};

interface BottomBarProps {
  onGotoProfile: () => void;
  onGotoSubscription: () => void;
  onDisconnect: () => void;
}

const BottomBar = (props: BottomBarProps) => {
  const { t } = useTranslation();
  const activePage = useActivePage();
  return (
    <Box
      display={{ base: "flex", sm: "none" }}
      flexDirection={"row"}
      alignItems={"center"}
      position={"absolute"}
      w={"100%"}
      h={"72px"}
      bottom={0}
      color={"white"}
      bg={"linear-gradient(120.91deg, #FD3A63 17.77%, #F364AB 81.27%)"}
    >
      <BottomBarItem
        icon={"/img/smile/webApp/menu/profile_icon.png"}
        text={t("smile:smilePlusHome_menuProfile")}
        isActive={activePage === "profile"}
        onClick={props.onGotoProfile}
      />
      <BottomBarItem
        icon={"/img/smile/webApp/menu/subscription_icon.png"}
        text={t("smile:smilePlusHome_menuSubscription")}
        isActive={activePage === "subscription"}
        onClick={props.onGotoSubscription}
      />
      <BottomBarItem
        icon={"/svg/smilePlusLogout.svg"}
        text={t("smile:smilePlusHome_menuDisconnect")}
        onClick={props.onDisconnect}
      />
    </Box>
  );
};

interface BottomBarItemProps {
  icon: string;
  text: string;
  isActive?: boolean;
  onClick: () => void;
}

const BottomBarItem = (props: BottomBarItemProps) => {
  return (
    <Box
      flex={1}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      cursor={"pointer"}
      onClick={props.onClick}
    >
      <Box display={"flex"} justifyContent={"center"} w={"20px"}>
        <NextImage src={props.icon} width={"20px"} height={"22px"} />
      </Box>
      <Text
        fontFamily="Montserrat"
        fontWeight={props.isActive ? "600" : "500"}
        fontSize="9px"
        lineHeight="11px"
        letterSpacing={"0.05em"}
        textTransform={"uppercase"}
        mt={"8px"}
      >
        {props.text}
      </Text>
    </Box>
  );
};

const useActivePage = (): "profile" | "subscription" | "unknown" => {
  const router = useRouter();

  if (router.pathname !== "/smile") {
    return "unknown";
  }

  if (!router.query["page"]) {
    return "profile";
  }

  if (router.query["page"] === "profile") {
    return "profile";
  }

  if (router.query["page"] === "subscription") {
    return "subscription";
  }

  return "unknown";
};

export default SmileSharedProfileLayout;

import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import { SmilePlusPlan } from "src/shared/types/User";
import { CardText } from "src/shared/components/Text/Text";
import { formatDate } from "src/utils/helpers/DateHelper";

export interface SmilePlusSubscriptionPlanControlProps {
  current?: boolean;
  expiration?: string | undefined;
  plan?: SmilePlusPlan;
  color: string;
  priceFormatter: Intl.NumberFormat | undefined;
  isButton?: boolean;
  modal?: boolean;
}

const SmileSubscriptionPlanView = ({
  current = false,
  expiration = undefined,
  plan,
  priceFormatter,
  color,
}: SmilePlusSubscriptionPlanControlProps) => {
  const { lang, t } = useTranslation();

  if (!plan) {
    return null;
  }

  return (
    <Flex
      direction="row"
      justify="space-between"
      alignItems="center"
      borderRadius="2px"
      background={color}
      opacity={0.9}
      px="25px"
      py="20px"
      w={"100%"}
    >
      <Flex direction="column">
        <CardText textAlign="left" color="white">
          {t(`smile:smilePlusSignUp_plan${plan.plan}Title`)}
        </CardText>
        {current && (
          <Text
            textAlign="left"
            color="#F3F3F3"
            fontFamily="Montserrat"
            fontStyle="normal"
            fontWeight="600"
            fontSize="11px"
            lineHeight="130%"
          >
            {plan.plan === "SMILE_PLUS_TRIAL"
              ? t(`smile:smilePlusHome_trialExpiration`, {
                  date: formatDate(lang, expiration) ?? "-",
                })
              : t(`smile:smilePlusHome_subscriptionRenewal`, {
                  date: formatDate(lang, expiration) ?? "-",
                })}
          </Text>
        )}
      </Flex>
      <Flex direction="column">
        <CardText textAlign="right" color="white">
          {t(`smile:smilePlusSignUp_plan${plan.plan}Text`, {
            price:
              plan.plan === "SMILE_PLUS_YEARLY"
                ? priceFormatter?.format(plan.price / 12) ?? plan.price / 12
                : priceFormatter?.format(plan.price) ?? plan.price,
          })}
        </CardText>
        {plan.plan === "SMILE_PLUS_YEARLY" && (
          <Text
            fontFamily="Montserrat"
            fontStyle="normal"
            fontWeight="600"
            fontSize="11px"
            lineHeight="120%"
            color="white"
          >
            {t(`smile:smilePlusSignUp_planSMILE_PLUS_YEARLYSubtitle`, {
              price: priceFormatter?.format(plan.price) ?? plan.price,
            })}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default SmileSubscriptionPlanView;

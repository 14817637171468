import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import useTranslation from "next-translate/useTranslation";
import { Box, FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react";
import { useAuthDispatch, useAuthState } from "src/shared/contexts/AuthContext";
import { Button } from "src/shared/components/Button";
import SmileSharedProfileLayout from "../../../../shared/components/Layouts/SmileSharedProfileLayout";

interface SmileProfileChangePhoneScreenProps {
  onClose: () => void;
}

interface FormFieldValues {
  phone: string;
}

const SmileProfileChangePhoneScreen = (props: SmileProfileChangePhoneScreenProps) => {
  const { t } = useTranslation();
  const { profile } = useAuthState();
  const { updateUserInfos } = useAuthDispatch();
  const formMethods = useForm<FormFieldValues>({
    mode: "onBlur",
    defaultValues: {
      phone: profile?.infos?.phone ?? "",
    },
  });
  const { errors, register } = formMethods;

  const handleSubmit = formMethods.handleSubmit(async (values) => {
    await updateUserInfos({
      phone: values.phone,
    });
    props.onClose();
  });

  return (
    <SmileSharedProfileLayout title={"Change mobile phone"} onGotoBack={props.onClose}>
      <Box maxW={{ base: "unset", sm: "400px" }}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit}>
            <FormControl id="phone" isInvalid={!!errors.phone} isRequired>
              <FormLabel fontWeight="bold" fontSize="16px" lineHeight="20px" mt="50px">
                {t("smile:smileSignup_phoneLabel")}
              </FormLabel>
              <Input
                variant="flushed"
                w="100%"
                name="phone"
                type="tel"
                ref={register({ required: t("smile:form_formRequiredField") })}
              />
              <FormErrorMessage>{errors.phone?.message}</FormErrorMessage>
            </FormControl>
            <Box mt={"88px"} display={"flex"} justifyContent={{ base: "center", sm: "unset" }}>
              <Button type="submit" isLoading={formMethods.formState.isSubmitting}>
                Update
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </SmileSharedProfileLayout>
  );
};

export default SmileProfileChangePhoneScreen;

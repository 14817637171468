import React, { useEffect } from "react";
import { pushRoute } from "src/utils/constants/routes";
import useTranslation from "next-translate/useTranslation";
import { hasAuthPermission } from "src/utils/hasAuthPermission";
import WithWaitForAuthentication from "src/shared/hocs/WithWaitForAuthentication";
import { useAuthDispatch, useAuthState } from "src/shared/contexts/AuthContext";
import { Flex, Spinner } from "@chakra-ui/react";
import { GraphQLClient } from "graphql-request";
import { VERIFY_TOKEN } from "src/graphql/Queries/common";
import SmileHome from "src/modules/smile/pages/Home";
import { APP_SIGNIN_ROUTE, APP_SIGNUP_ROUTE } from "src/modules/smile/shared/consts";

const SmileHomePage = (props: any) => {
  const { isAuthenticated, profile } = useAuthState();
  const { lang } = useTranslation();
  const { authByToken } = useAuthDispatch();

  useEffect(() => {
    if (props.validToken) {
      authByToken(props.authToken);
    } else if (!isAuthenticated) {
      pushRoute(APP_SIGNIN_ROUTE, lang);
    } else if (profile) {
      if ("smilePlusStatePlan" in profile && !hasAuthPermission(profile?.smilePlusStatePlan)) {
        pushRoute(APP_SIGNUP_ROUTE, lang);
      }
    }
  }, []);

  if (!isAuthenticated) {
    return (
      <Flex h="100vh" w="100vw" justify="center" align="center">
        <Spinner size="xl" color="brandBlue.100" thickness="5px" />
      </Flex>
    );
  }

  return <SmileHome />;
};

export async function getServerSideProps(req: any) {
  if (req.query.authToken) {
    const client = new GraphQLClient(process.env.NEXT_PUBLIC_API_URL || "", {
      headers: {
        "X-Api-Key": process.env.NEXT_PUBLIC_API_KEY || "",
      },
    });
    const res = await client.request(VERIFY_TOKEN, { authToken: req.query.authToken });
    if (res.verifyToken) {
      return {
        props: {
          validToken: true,
          authToken: req.query.authToken,
        },
      };
    }
  }
  return {
    props: {
      validToken: false,
    },
  };
}

export default WithWaitForAuthentication(SmileHomePage);

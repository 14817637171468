import React, { useEffect } from "react";
import { useAuthDispatch, useAuthState } from "src/shared/contexts/AuthContext";
import SmileChangePlanSelectedView from "../Views/SmileChangePlanSelectedView";

const SmileChangePlanForm = () => {
  const { profile } = useAuthState();
  const { getProfile } = useAuthDispatch();

  useEffect(() => {
    if (!profile?.smilePlusPlanInfos?.plan) {
      getProfile();
    }
  }, []);

  return <SmileChangePlanSelectedView />;
};

export default SmileChangePlanForm;

import React, { useState, ReactNode } from "react";
import { Box, Text } from "@chakra-ui/react";
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import { useMutation } from "@apollo/client";
import { ChangeCreditCardVariables, ChangeCreditCardPayload } from "src/shared/types/Mutations/User";
import { useStripeToast } from "src/shared/hooks/useStripeToast";
import useTranslation from "next-translate/useTranslation";
import { CHANGE_CREDIT_CARD } from "src/graphql/Mutations/User";
import { useAuthDispatch } from "src/shared/contexts/AuthContext";
import { themeConsts } from "src/utils/theme";
import { Button } from "src/shared/components/Button";

const SmilePaymentForm = ({ onComplete }: { onComplete: () => void }) => {
  const [cardReady, setCardReady] = useState({ number: false, expiration: false, cvc: false });
  const isCardReady = cardReady.number && cardReady.expiration && cardReady.cvc;
  const { sendSuccessToast, sendStripeErrorToast } = useStripeToast();
  const [changeCreditCard] = useMutation<ChangeCreditCardPayload, ChangeCreditCardVariables>(CHANGE_CREDIT_CARD, {
    onError: (error) => sendStripeErrorToast(error.message),
  });
  const { getProfile } = useAuthDispatch();

  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();

  const onSubmit = async () => {
    // Handle card validation using stripe
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    const cardNumberElement = elements.getElement(CardNumberElement);
    if (cardNumberElement !== null) {
      const payload = await stripe.createToken(cardNumberElement);
      if (payload.token !== undefined) {
        const res = await changeCreditCard({
          variables: { cardToken: payload.token.id },
        });
        if (res?.data?.changeCreditCard) {
          sendSuccessToast();
          getProfile();
        }
      }
      onComplete();
    }
  };

  return (
    <Box>
      <Box mb={"30px"}>
        <CardField
          label={t("smile:pixPayment_paymentCardNumber")}
          input={
            <CardNumberElement
              onChange={(e) => {
                if (e.complete) {
                  setCardReady((old) => ({ ...old, number: true }));
                }
              }}
            />
          }
        />
      </Box>
      <Box display={"flex"} mb={{ base: "100px", md: "70px" }}>
        <Box flex={1}>
          <CardField
            label={t("smile:pixPayment_paymentModalExpiration")}
            input={
              <CardExpiryElement
                onChange={(e) => {
                  if (e.complete) {
                    setCardReady((old) => ({ ...old, expiration: true }));
                  }
                }}
              />
            }
          />
        </Box>
        <Box w={{ base: "20px", md: "55px" }} />
        <Box flex={1}>
          <CardField
            label={t("smile:pixPayment_paymentModalCVV")}
            input={
              <CardCvcElement
                onChange={(e) => {
                  if (e.complete) {
                    setCardReady((old) => ({ ...old, cvc: true }));
                  }
                }}
              />
            }
          />
        </Box>
      </Box>
      <Box display={{ base: "none", md: "block" }}>
        <Button isDisabled={!isCardReady} onClick={() => onSubmit()}>
          {t("smile:pixPayment_paymentModalUpdate")}
        </Button>
      </Box>
      <Box display={{ base: "block", md: "none" }}>
        <Button isDisabled={!isCardReady} onClick={() => onSubmit()} isFullWidth={true}>
          {t("smile:pixPayment_paymentModalUpdate")}
        </Button>
      </Box>
    </Box>
  );
};

interface CardFieldProps {
  label: string;
  input: ReactNode;
}

const CardField = (props: CardFieldProps) => {
  return (
    <Box>
      <Text
        fontFamily={"Montserrat"}
        fontWeight={"600"}
        fontSize={"11px"}
        lineHeight={"140%"}
        color={themeConsts.darkGrey}
        mb={"10px"}
      >
        {props.label}
      </Text>
      <Box
        fontFamily={"Montserrat"}
        fontWeight={"600"}
        fontSize={"11px"}
        lineHeight={"140%"}
        color={themeConsts.darkGrey}
        pb={"5px"}
        borderBottom={`1px solid ${themeConsts.mediumGrey}`}
      >
        {props.input}
      </Box>
    </Box>
  );
};

export default SmilePaymentForm;

import React, { useEffect, useState } from "react";
import useTranslation from "next-translate/useTranslation";
import { Card, SmilePlusSubscriptionPlanProductEnum, SubscriptionPlanStateEnum } from "types/User";
import { Box, HStack, VStack, Text, Flex, Skeleton } from "@chakra-ui/react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { SMILE_PLUS_CHANGE_PLAN } from "src/graphql/Mutations/Plan";
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import { Button } from "src/shared/components/Button";
import { useAuthDispatch } from "src/shared/contexts/AuthContext";
import { useStripeToast } from "src/shared/hooks/useStripeToast";
import { SMILE_PLUS_PREVIEW_CHANGE_PLANS } from "src/graphql/Queries/Plan";
import { SmilePlusPreviewChangePlanPayload, SmilePlusPreviewChangePlanVariables } from "types/Queries/Plan";
import { findSmilePlusPromoDetails } from "src/utils/promoCodes";
import { formatDateWithSeconds } from "src/utils/helpers/DateHelper";
import { themeConsts } from "src/utils/theme";
import SmileProfileModalLayout from "../../../Profile/ProfileModals/components/Layout/SmileProfileModalLayout";
import { SmileSharedRadioCardProps } from "src/modules/smile/shared/components/Forms/SmileSharedRadioCard";
import SmileSubscriptionPlanView from "./SmileSubscriptionPlanView";
import { SmilePlusChangePlanPayload, SmilePlusChangePlanVariables } from "types/Mutations/Plan";

const checkIfCreditCardIsNeeded = (statePlan: SubscriptionPlanStateEnum, card: Card | null) => {
  if (card === null) {
    return true;
  }
  switch (statePlan) {
    case SubscriptionPlanStateEnum.ACTIVE:
    case SubscriptionPlanStateEnum.ACTIVE_WILL_EXPIRE:
    case SubscriptionPlanStateEnum.ACTIVE_WILL_DOWNGRADE:
      return false;
    default:
      return true;
  }
};

const SmileChangePlanModal = ({
  statePlan,
  radioCardProps,
  closeModal,
  card,
  coupon,
}: {
  statePlan: SubscriptionPlanStateEnum;
  radioCardProps: SmileSharedRadioCardProps;
  closeModal: () => void;
  card: Card | null;
  coupon: string;
}) => {
  const { t, lang } = useTranslation();
  const [needsToPay, setNeedsToPay] = useState(false);
  const [cardReady, setCardReady] = useState({ number: false, expiration: false, cvc: false });
  const [isUpdating, setIsUpdating] = useState(false);
  const isCardReady = cardReady.number && cardReady.expiration && cardReady.cvc;
  const stripe = useStripe();
  const { sendSuccessToast, sendStripeErrorToast } = useStripeToast();
  const elements = useElements();
  const { getProfile } = useAuthDispatch();
  const promoDetails = findSmilePlusPromoDetails(coupon);

  const [smilePlusChangePlanMutation] = useMutation<SmilePlusChangePlanPayload, SmilePlusChangePlanVariables>(
    SMILE_PLUS_CHANGE_PLAN
  );

  const [smilePlusPreviewChangePlan, { data: smilePlusPreviewChangePlanData, loading }] = useLazyQuery<
    SmilePlusPreviewChangePlanPayload,
    SmilePlusPreviewChangePlanVariables
  >(SMILE_PLUS_PREVIEW_CHANGE_PLANS);

  /**
   * Fetch the price user will have to pay.
   * We need to fetch in useEffect because modal renders even when closed
   */
  useEffect(() => {
    if (radioCardProps.plan?.plan && smilePlusPreviewChangePlanData === undefined) {
      smilePlusPreviewChangePlan({
        variables: { toPlan: radioCardProps.plan.plan, coupon: coupon },
      });
    }
  }, [radioCardProps.plan?.plan, smilePlusPreviewChangePlanData]);

  const handleNewCard = async () => {
    setIsUpdating(true);
    try {
      if (elements && stripe) {
        const cardNumberElement = elements.getElement(CardNumberElement);
        if (cardNumberElement !== null) {
          const payload = await stripe.createToken(cardNumberElement);
          await handleSubscribe(payload.token?.id);
        }
      }
    } finally {
      setIsUpdating(false);
    }
  };

  const handleSubscribe = async (cardToken?: string) => {
    setIsUpdating(true);
    try {
      if (!stripe) {
        throw new Error("Stripe has not loaded yet");
      }

      if (!radioCardProps.plan) {
        throw new Error("No plan chosen");
      }

      const res = await smilePlusChangePlanMutation({
        variables: { toPlan: radioCardProps.plan.plan, cardToken: cardToken, coupon: coupon },
      });

      if (!res.data) {
        throw new Error("Change plan request error");
      }

      const threeDSecurePaymentIntentSecret = res.data.smilePlusChangePlan.threeDSecurePaymentIntentSecret;

      if (threeDSecurePaymentIntentSecret) {
        const { error: stripeError } = await stripe.confirmCardPayment(threeDSecurePaymentIntentSecret);

        if (stripeError) {
          throw new Error(stripeError.type);
        }
      }

      sendSuccessToast();
    } catch (err: any) {
      sendStripeErrorToast(err.message ?? err);
    } finally {
      getProfile();
      closeModal();
      setIsUpdating(false);
    }
  };

  if (!radioCardProps.plan) {
    return null;
  }

  if (loading) {
    return <Skeleton w="650px" h="200px" />;
  }

  return (
    <SmileProfileModalLayout
      title={
        needsToPay
          ? t("smile:profile_profileChangePlanAddPaymentMethodTitle")
          : t("smile:profile_profileChangePlanModalTitle")
      }
      subtitle={needsToPay ? t("smile:profile_profileChangePlanAddPaymentMethodSubtitle") : undefined}
      width="unset"
      height={needsToPay ? "552px" : "unset"}
    >
      {needsToPay ? (
        <VStack w="100%" paddingRight="105px" pl="41px">
          <HStack w="100%" mt="50px" justify="space-between">
            <Text fontWeight="bold" fontSize="14px" lineHeight="20px" fontFamily="Montserrat" fontStyle="normal">
              {t("smile:pixPayment_paymentModalCardNumber")}
            </Text>
            <Box w="384px" border="1px solid #CCCCCC" borderRadius="4px" py="14px" pl="20px">
              <CardNumberElement
                onChange={(e) => {
                  if (e.complete) {
                    setCardReady((old) => ({ ...old, number: true }));
                  }
                }}
              />
            </Box>
          </HStack>
          <HStack w="100%" justify="space-between">
            <Text
              fontWeight="bold"
              fontSize="14px"
              lineHeight="20px"
              fontFamily="Montserrat"
              fontStyle="normal"
              color="#000000"
            >
              {t("smile:pixPayment_paymentModalExpiration")}
            </Text>
            <Box w="384px" border="1px solid #CCCCCC" borderRadius="4px" py="14px" pl="20px">
              <CardExpiryElement
                onChange={(e) => {
                  if (e.complete) {
                    setCardReady((old) => ({ ...old, expiration: true }));
                  }
                }}
              />
            </Box>
          </HStack>
          <HStack w="100%" justify="space-between">
            <Text fontWeight="bold" fontSize="14px" lineHeight="20px" fontFamily="Montserrat" fontStyle="normal">
              {t("smile:pixPayment_paymentModalCVV")}
            </Text>
            <Box w="384px" border="1px solid #CCCCCC" borderRadius="4px" py="14px" pl="20px">
              <CardCvcElement
                onChange={(e) => {
                  if (e.complete) {
                    setCardReady((old) => ({ ...old, cvc: true }));
                  }
                }}
              />
            </Box>
          </HStack>
          <Flex w="100%" justify="flex-end" paddingTop="28px" paddingBottom="118px">
            <Button onClick={() => isCardReady && handleNewCard()} isDisabled={!isCardReady} isLoading={isUpdating}>
              {t("smile:pixPayment_paymentModalUpdate")}
            </Button>
          </Flex>
        </VStack>
      ) : (
        <VStack spacing="30px" justify="center" align="center" width="100%" px={"20px"}>
          <SmileSubscriptionPlanView
            plan={radioCardProps.plan}
            color={radioCardProps.color}
            priceFormatter={radioCardProps.priceFormatter}
            modal={true}
          />
          {promoDetails && radioCardProps.plan.plan === SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_YEARLY && (
            <Text
              fontFamily={"Montserrat"}
              fontSize={"17px"}
              fontWeight={"bold"}
              lineHeight="125%"
              color={themeConsts.smile}
              textAlign={"center"}
            >
              {t("smile:smilePlusSubscription_changePlanPromoMessage", {
                discount: promoDetails.discount,
              })}
            </Text>
          )}
          <Text fontFamily="Montserrat" fontStyle="normal" fontWeight="500" fontSize="16px" lineHeight="20px">
            {t("smile:pixSubscription_priceToPay")}
            {smilePlusPreviewChangePlanData &&
              radioCardProps?.priceFormatter?.format(
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                smilePlusPreviewChangePlanData.smilePlusPreviewChangePlan.amountToPayNow
              )}
          </Text>
          <Text fontFamily="Montserrat" fontStyle="normal" fontWeight="500" fontSize="16px" lineHeight="20px">
            {t("smile:pixSubscription_newDate")}
            {smilePlusPreviewChangePlanData &&
              formatDateWithSeconds(lang, smilePlusPreviewChangePlanData.smilePlusPreviewChangePlan.planChangeDate)}
          </Text>
          <Flex justify="center" pb="36px" w="100%">
            <Button
              onClick={() => (checkIfCreditCardIsNeeded(statePlan, card) ? setNeedsToPay(true) : handleSubscribe())}
              isLoading={isUpdating}
            >
              {t("smile:pixSubscription_confirm")}
            </Button>
          </Flex>
        </VStack>
      )}
    </SmileProfileModalLayout>
  );
};

export default SmileChangePlanModal;

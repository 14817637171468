import React from "react";
import NextImage from "next/image";
import useTranslation from "next-translate/useTranslation";
import { Box } from "@chakra-ui/react";
import * as consts from "src/shared/consts";
import { useAuthState } from "src/shared/contexts/AuthContext";
import * as Texts from "src/shared/components/Texts";
import SmileSharedProfileLayout from "../../../../shared/components/Layouts/SmileSharedProfileLayout";

interface SmileProfileHomeScreenProps {
  onChangeName: () => void;
  onChangeEmail: () => void;
  onChangePhone: () => void;
  onChangePassword: () => void;
}

const SmileProfileHomeScreen = (props: SmileProfileHomeScreenProps) => {
  const { t } = useTranslation();
  const { profile } = useAuthState();

  const name = `${profile?.infos?.firstName ?? ""} ${profile?.infos?.lastName ?? ""}`.trim();
  const email = profile?.email ?? "-";
  const phone = profile?.infos?.phone ?? "-";

  return (
    <SmileSharedProfileLayout
      title={t(`smile:smilePlusHome_profileTitle`) + ` ${profile?.infos?.firstName}`}
      subtitle={"You can find here your personal informations"}
    >
      <Field label={t("smile:smilePlusHome_nameLabel")} value={name} onEdit={props.onChangeName} />
      <Field label={t("smile:smilePlusHome_emailLabel")} value={email} onEdit={props.onChangeEmail} />
      <Field label={t("smile:smilePlusHome_phoneLabel")} value={phone} onEdit={props.onChangePhone} />
      <Field label={t("smile:smilePlusHome_passwordLabel")} value={"XXXXXXXX"} onEdit={props.onChangePassword} />
    </SmileSharedProfileLayout>
  );
};

interface FieldProps {
  label: string;
  value: string;
  onEdit: () => void;
}

const Field = (props: FieldProps) => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      px={"25px"}
      py={"12px"}
      bg={"rgba(255, 255, 255, 0.8)"}
      border={"1px solid #BBD1F2"}
      borderRadius={"2px"}
      boxShadow={"0px 20px 32px rgba(70, 25, 164, 0.05)"}
      mb={"24px"}
    >
      <Box flex={1}>
        <Texts.H5M mb={"4px"}>{props.label}</Texts.H5M>
        <Texts.Normal color={consts.COLOR_GRAY_3}>{props.value}</Texts.Normal>
      </Box>
      <Box display={"flex"} cursor={"pointer"} onClick={props.onEdit}>
        <NextImage src={"/svg/edit.svg"} width={"22px"} height={"22px"} />
      </Box>
    </Box>
  );
};

export default SmileProfileHomeScreen;

import { IS_HYBRIDGE } from "src/modules/smile/shared/consts";
import { SmilePlusSubscriptionPlanProductEnum } from "types/User";
import { SMILE_PLUS_PRICE_MONTHLY, SMILE_PLUS_PRICE_YEARLY } from "src/utils/prices";

export interface PromoDetails {
  discount: number;
}

const smilePlusPromos = new Map<string, PromoDetails>(
  IS_HYBRIDGE
    ? [
        // ["hsd22", { discount: 14.141414141414 }],
        // ["hsdsym22", { discount: 39.3939393939 }],
        ["hsdfree!", { discount: 100 }],
        ["levelup", { discount: 100 }],
      ]
    : [
        ["ctcformations", { discount: 50 }],
        ["blackfriday", { discount: 30 }],
      ]
);

export const calculatePromoPrice = (details: PromoDetails, price: number): number => {
  return Math.floor(price * (1 - details.discount / 100));
};

export const findSmilePlusPromoDetails = (code: string): PromoDetails | undefined => {
  return smilePlusPromos.get(code.trim().toLowerCase());
};

export const calculateSmilePlusPrice = (
  plan: SmilePlusSubscriptionPlanProductEnum,
  promoDetails: PromoDetails | undefined
): number => {
  if (plan === SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_MONTHLY) {
    return IS_HYBRIDGE && promoDetails
      ? calculatePromoPrice(promoDetails, SMILE_PLUS_PRICE_MONTHLY)
      : SMILE_PLUS_PRICE_MONTHLY;
  }

  if (plan === SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_YEARLY) {
    return !IS_HYBRIDGE && promoDetails
      ? calculatePromoPrice(promoDetails, SMILE_PLUS_PRICE_YEARLY)
      : SMILE_PLUS_PRICE_YEARLY;
  }

  return 0;
};

export const isSmilePlusValidPlanPromo = (plan: SmilePlusSubscriptionPlanProductEnum): boolean => {
  return IS_HYBRIDGE
    ? plan === SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_MONTHLY
    : plan === SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_YEARLY;
};

import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import useTranslation from "next-translate/useTranslation";
import { useMutation } from "@apollo/client";
import { Box, FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react";
import { UPDATE_PASSWORD } from "src/graphql/Mutations/User";
import { Button } from "src/shared/components/Button";
import SmileSharedProfileLayout from "../../../../shared/components/Layouts/SmileSharedProfileLayout";

interface SmileProfileChangePasswordScreenProps {
  onClose: () => void;
}

interface FormFieldValues {
  password: string;
  confirmPassword: string;
}

const SmileProfileChangePasswordScreen = (props: SmileProfileChangePasswordScreenProps) => {
  const { t } = useTranslation();
  const formMethods = useForm<FormFieldValues>({ mode: "onBlur" });
  const { errors, register, watch } = formMethods;
  const [updatePassword] = useMutation(UPDATE_PASSWORD);

  const handleSubmit = formMethods.handleSubmit(async (values) => {
    await updatePassword({
      variables: {
        newPassword: values.password,
      },
    });
    props.onClose();
  });

  return (
    <SmileSharedProfileLayout title={"Change password"} onGotoBack={props.onClose}>
      <Box maxW={{ base: "unset", sm: "400px" }}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit}>
            <FormControl id="password" isInvalid={!!errors.password} isRequired>
              <FormLabel fontWeight="bold" fontSize="16px" lineHeight="20px" mt="50px">
                {t("smile:pixSignup_passwordLabel")}
              </FormLabel>
              <Input
                variant="flushed"
                w="100%"
                name="password"
                type="password"
                ref={register({
                  required: t("smile:form_formRequiredField"),
                  minLength: {
                    value: 7,
                    message: t("smile:form_formPasswordLength"),
                  },
                })}
              />
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id="confirmPassword" isInvalid={!!errors.confirmPassword} isRequired>
              <FormLabel fontWeight="bold" fontSize="16px" lineHeight="20px" mt="50px">
                {t("smile:pixSignup_confirmPasswordLabel")}
              </FormLabel>
              <Input
                variant="flushed"
                w="100%"
                name="confirmPassword"
                type="password"
                ref={register({
                  required: t("smile:form_formRequiredField"),
                  validate: (v) => v === watch("password") || t("smile:form_formPasswordMismatch"),
                })}
              />
              <FormErrorMessage>{errors.confirmPassword?.message}</FormErrorMessage>
            </FormControl>
            <Box mt={"88px"} display={"flex"} justifyContent={{ base: "center", sm: "unset" }}>
              <Button type="submit" isLoading={formMethods.formState.isSubmitting}>
                Update
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </SmileSharedProfileLayout>
  );
};

export default SmileProfileChangePasswordScreen;

import React, { useState } from "react";
import { Box, Modal, ModalOverlay, ModalContent, useToast } from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import { SubscriptionPlanStateEnum, SmilePlusSubscriptionPlanProductEnum, UdiniProduct } from "types/User";
import { formatDate } from "src/utils/helpers/DateHelper";
import { useAuthDispatch, useAuthState } from "src/shared/contexts/AuthContext";
import {
  CancelSubsriptionPayload,
  CancelSubsriptionVariables,
  SmilePlusChangePlanPayload,
  SmilePlusChangePlanVariables,
} from "src/shared/types/Mutations/Plan";
import { CANCEL_SUBSCRIPTION, SMILE_PLUS_CHANGE_PLAN } from "src/graphql/Mutations/Plan";
import * as Texts from "src/shared/components/Texts";
import { Button } from "src/shared/components/Button";
import useTranslation from "next-translate/useTranslation";

const SmileCancellation = () => {
  const { t } = useTranslation();
  const { profile } = useAuthState();
  const { getProfile } = useAuthDispatch();
  const toast = useToast();
  const canCancel = profile?.smilePlusStatePlan !== SubscriptionPlanStateEnum.ACTIVE_WILL_EXPIRE;
  const canStopCancellation =
    profile?.smilePlusStatePlan === SubscriptionPlanStateEnum.ACTIVE_WILL_EXPIRE &&
    profile?.smilePlusPlanInfos?.plan !== SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_TRIAL;
  const [isStopCancellationSubmitting, setIsStopCancellationSubmitting] = useState(false);
  const [cancelModalStatus, setCancelModalStatus] = useState<CancelModalStatus>("closed");

  const [cancelSubscriptionMutation] = useMutation<CancelSubsriptionPayload, CancelSubsriptionVariables>(
    CANCEL_SUBSCRIPTION
  );

  const [smilePlusChangePlanMutation] = useMutation<SmilePlusChangePlanPayload, SmilePlusChangePlanVariables>(
    SMILE_PLUS_CHANGE_PLAN
  );

  const cancel = async () => {
    await cancelSubscriptionMutation({
      variables: { product: UdiniProduct.SMILE_PLUS, forceCancelNow: false },
    });
    getProfile();
  };

  const stopCancellation = async () => {
    await smilePlusChangePlanMutation({
      variables: {
        toPlan: profile?.smilePlusPlanInfos?.plan ?? SmilePlusSubscriptionPlanProductEnum.NONE,
      },
    });
    getProfile();
  };

  const handleCancelModalOpen = () => {
    setCancelModalStatus("ready");
  };

  const handleCancelModalClose = () => {
    setCancelModalStatus("closed");
  };

  const handleCancelModalSubmit = async () => {
    try {
      setCancelModalStatus("in-progress");
      await cancel();
      setCancelModalStatus("success");
    } catch (_) {
      setCancelModalStatus("ready");
      toast({
        position: "top",
        title: "The request to cancel your plan failed",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleStopCancellationSubmit = async () => {
    setIsStopCancellationSubmitting(true);
    try {
      await stopCancellation();
    } catch (_) {
      toast({
        position: "top",
        title: "The request to stop the cancellation failed",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsStopCancellationSubmitting(false);
    }
  };

  return (
    <>
      <Box mt={"40px"} display={"flex"} justifyContent={{ base: "center", sm: "unset" }}>
        {canCancel && (
          <Button variant={"white"} onClick={handleCancelModalOpen}>
            {t("smile:smilePlusSubscription_cancelButton")}
          </Button>
        )}
        {canStopCancellation && (
          <Button variant={"white"} isLoading={isStopCancellationSubmitting} onClick={handleStopCancellationSubmit}>
            {t("smile:smilePlusSubscription_stopCancellationButton")}
          </Button>
        )}
      </Box>
      <CancelModal status={cancelModalStatus} onClose={handleCancelModalClose} onSubmit={handleCancelModalSubmit} />
    </>
  );
};

type CancelModalStatus = "closed" | "ready" | "in-progress" | "success";

interface CancelModalProps {
  status: CancelModalStatus;
  onClose: () => void;
  onSubmit: () => void;
}

const CancelModal = (props: CancelModalProps) => {
  const { t, lang } = useTranslation();
  const { profile } = useAuthState();
  return (
    <Modal isOpen={props.status !== "closed"} isCentered={true} onClose={props.onClose}>
      <ModalOverlay>
        <ModalContent maxWidth={"710px"} py={"100px"} position={"relative"}>
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Texts.P textAlign={"center"} mb={"50px"}>
              {t("smile:smilePlusSubscription_cancelModalConfirmMessage")}
            </Texts.P>
            <Box display={"flex"} flexDirection={{ base: "column", sm: "row" }}>
              <Box mr={{ base: "unset", sm: "50px" }} mb={{ base: "25px", sm: "unset" }}>
                <Button onClick={props.onClose}>{t("smile:smilePlusSubscription_cancelModalCancelButton")}</Button>
              </Box>
              <Box>
                <Button isLoading={props.status === "in-progress"} onClick={props.onSubmit}>
                  {t("smile:smilePlusSubscription_cancelModalSubmitButton")}
                </Button>
              </Box>
            </Box>
          </Box>
          {props.status === "success" && (
            <Box
              position={"absolute"}
              left={0}
              top={0}
              right={0}
              bottom={0}
              bg={"white"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              px={{ base: "20px", sm: "100px" }}
              cursor={"pointer"}
              onClick={props.onClose}
            >
              <Texts.P textAlign={"center"}>
                {t("smile:smilePlusSubscription_cancelModalSuccessMessage", {
                  date: formatDate(lang, profile?.smilePlusPlanInfos?.nextBilling),
                })}
              </Texts.P>
            </Box>
          )}
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default SmileCancellation;

import React from "react";
import useTranslation from "next-translate/useTranslation";
import { Box, Text } from "@chakra-ui/react";
import { themeConsts } from "src/utils/theme";
import NextImage from "next/image";
import { useAuthState } from "src/shared/contexts/AuthContext";

interface SmilePaymentProps {
  onEdit: () => void;
}

const SmilePayment = ({ onEdit }: SmilePaymentProps) => {
  const { t } = useTranslation();
  const { profile } = useAuthState();
  const card = profile?.smilePlusPlanInfos?.card;

  return (
    <Box
      bg={"rgba(255, 255, 255, 0.8)"}
      border={"2px solid #BBD1F2"}
      borderRadius={"2px"}
      boxShadow={"0px 20px 32px rgba(70, 25, 164, 0.05)"}
      p={{ base: "20px", md: "30px" }}
      pb={card && { base: "40px", md: "80px" }}
      mt={{ base: "25px", md: "40px" }}
    >
      <Box display={"flex"} alignItems={"center"} mb={card && "25px"}>
        <Text
          flex={1}
          fontFamily={"Montserrat"}
          fontWeight={"bold"}
          fontSize={"16px"}
          lineHeight={"20px"}
          color={themeConsts.black}
        >
          {t("smile:pixPayment_paymentTitle")}
        </Text>
        <Box display={"flex"} cursor={"pointer"} onClick={onEdit}>
          <NextImage src={"/svg/edit.svg"} width={"22px"} height={"22px"} />
        </Box>
      </Box>
      {card && (
        <>
          <Box mb={"30px"}>
            <CardField label={t("smile:pixPayment_paymentCardNumber")} value={`XXXX XXXX XXXX ${card.last4}`} />
          </Box>
          <Box display={"flex"}>
            <Box flex={1}>
              <CardField
                label={t("smile:pixPayment_paymentModalExpiration")}
                value={`${card?.exp_month}/${card?.exp_year}`}
              />
            </Box>
            <Box w={{ base: "20px", md: "55px" }} />
            <Box flex={1}>
              <CardField label={t("smile:pixPayment_paymentModalCVV")} value={"XXX"} />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

interface CardFieldProps {
  label: string;
  value: string;
}

const CardField = (props: CardFieldProps) => {
  return (
    <Box>
      <Text
        fontFamily={"Montserrat"}
        fontWeight={"600"}
        fontSize={"11px"}
        lineHeight={"140%"}
        color={themeConsts.black}
        mb={"10px"}
      >
        {props.label}
      </Text>
      <Box
        fontFamily={"Montserrat"}
        fontWeight={"600"}
        fontSize={"11px"}
        lineHeight={"140%"}
        color={themeConsts.darkGrey}
        pb={"5px"}
        borderBottom={`1px solid ${themeConsts.mediumGrey}`}
      >
        {props.value}
      </Box>
    </Box>
  );
};

export default SmilePayment;

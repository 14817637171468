import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { SmilePlusPlan, SmilePlusSubscriptionPlanProductEnum, SubscriptionPlanStateEnum } from "types/User";
import useTranslation from "next-translate/useTranslation";
import { useQuery } from "@apollo/client";
import { SMILE_PLUS_PLANS } from "src/graphql/Queries/Plan";
import { SmilePlusPlansPayload } from "types/Queries/Plan";
import { useSentry } from "src/services/Sentry";
import customTheme, { themeConsts } from "src/utils/theme";
import { CopyText } from "src/shared/components/Text/Text";
import { useAuthDispatch, useAuthState } from "src/shared/contexts/AuthContext";
import { findSmilePlusPromoDetails } from "src/utils/promoCodes";
import SmileSubscriptionPlanView from "./SmileSubscriptionPlanView";
import SmileChangePlanModal from "./SmileChangePlanModal";
import SmileChangePlanSubtitle from "./SmileChangePlanSubtitle";
import * as Texts from "src/shared/components/Texts";
import { IS_HYBRIDGE } from "src/modules/smile/shared/consts";

const { colors } = customTheme;

const cardColor = [colors.brandBlue["100"], "#FF2D55", "#D389F9"];

const getColorFromPlan = (currentPlan: SmilePlusSubscriptionPlanProductEnum) => {
  switch (currentPlan) {
    case SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_TRIAL:
      return cardColor[0];
    case SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_MONTHLY:
      return cardColor[1];
    case SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_YEARLY:
      return cardColor[2];
    default:
      return cardColor[1];
  }
};

/**
 * This component is kinda useless now that any current plan shows every other plans.
 * Might want to remove it and use a filter instead.
 */
const PlansFromCurrentPlan = ({
  currentPlan,
  priceFormatter,
  plans,
  setChosenPlan,
}: {
  currentPlan: SmilePlusSubscriptionPlanProductEnum;
  priceFormatter: Intl.NumberFormat | undefined;
  plans: SmilePlusPlan[];
  setChosenPlan: React.Dispatch<React.SetStateAction<SmilePlusSubscriptionPlanProductEnum>>;
}) => {
  switch (currentPlan) {
    case SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_TRIAL:
      return (
        <>
          <Box
            as="button"
            w={"100%"}
            onClick={() => setChosenPlan(SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_MONTHLY)}
          >
            <SmileSubscriptionPlanView
              plan={plans.find((plan) => plan.plan === SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_MONTHLY)}
              color={cardColor[1]}
              priceFormatter={priceFormatter}
            />
          </Box>
          <Box
            as="button"
            w={"100%"}
            onClick={() => setChosenPlan(SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_YEARLY)}
          >
            <SmileSubscriptionPlanView
              plan={plans.find((plan) => plan.plan === SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_YEARLY)}
              color={cardColor[2]}
              priceFormatter={priceFormatter}
            />
          </Box>
        </>
      );
    case SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_MONTHLY:
      return (
        <>
          <Box
            as="button"
            w={"100%"}
            onClick={() => setChosenPlan(SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_YEARLY)}
          >
            <SmileSubscriptionPlanView
              plan={plans.find((plan) => plan.plan === SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_YEARLY)}
              color={cardColor[2]}
              priceFormatter={priceFormatter}
            />
          </Box>
        </>
      );
    case SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_YEARLY:
      return (
        <>
          <Box
            as="button"
            w={"100%"}
            onClick={() => setChosenPlan(SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_MONTHLY)}
          >
            <SmileSubscriptionPlanView
              plan={plans.find((plan) => plan.plan === SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_MONTHLY)}
              color={cardColor[1]}
              priceFormatter={priceFormatter}
            />
          </Box>
        </>
      );
    case SmilePlusSubscriptionPlanProductEnum.NONE:
      return (
        <>
          <Box
            as="button"
            w={"100%"}
            onClick={() => setChosenPlan(SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_MONTHLY)}
          >
            <SmileSubscriptionPlanView
              plan={plans.find((plan) => plan.plan === SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_MONTHLY)}
              color={cardColor[1]}
              priceFormatter={priceFormatter}
            />
          </Box>
          <Box
            as="button"
            w={"100%"}
            onClick={() => setChosenPlan(SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_YEARLY)}
          >
            <SmileSubscriptionPlanView
              plan={plans.find((plan) => plan.plan === SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_YEARLY)}
              color={cardColor[2]}
              priceFormatter={priceFormatter}
            />
          </Box>
        </>
      );
  }
};

const SmileChangePlanSelectedView = () => {
  const { profile } = useAuthState();
  const { t, lang } = useTranslation();
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeInput, setPromoCodeInput] = useState("");
  const [promoCodeMessage, setPromoCodeMessage] = useState("");
  const { getProfile } = useAuthDispatch();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: planData, loading: plansLoading } = useQuery<SmilePlusPlansPayload>(SMILE_PLUS_PLANS);
  const { log, sendError } = useSentry();
  const [chosenPlan, setChosenPlan] = useState(SmilePlusSubscriptionPlanProductEnum.NONE);
  const isTrialExpired = profile?.smilePlusStatePlan === SubscriptionPlanStateEnum.TRIAL_EXPIRED;
  const isPaymentFailed = profile?.smilePlusStatePlan === SubscriptionPlanStateEnum.PAYMENT_PENDING;

  const currentPlanObject = useMemo(
    () =>
      planData?.smilePlusPlans?.plans?.find((plan) => {
        return plan.plan === profile?.smilePlusPlanInfos?.plan;
      }),
    [profile?.smilePlusPlanInfos?.plan, plansLoading]
  );

  useEffect(() => {
    if (chosenPlan === SmilePlusSubscriptionPlanProductEnum.NONE) return;
    if (profile?.smilePlusPlanInfos?.plan) {
      if (chosenPlan !== profile?.smilePlusPlanInfos?.plan) {
        if (profile.statePlan === SubscriptionPlanStateEnum.ACTIVE_WILL_DOWNGRADE) {
          toast({
            position: "top",
            title: "Information",
            description: t("smile:pixSubscription_toastInformationActiveWillDowngrade"),
            status: "info",
            duration: 9000,
            isClosable: true,
          });
        } else {
          onOpen();
        }
      }
    }
  }, [chosenPlan, profile?.smilePlusPlanInfos?.plan]);

  const priceFormatter = useMemo(() => {
    try {
      return new Intl.NumberFormat(lang, {
        style: "currency",
        currency: planData?.smilePlusPlans.currency,
        maximumSignificantDigits: 3,
      });
    } catch (e: any) {
      log(`Unknown locale or currency : ${lang} | ${planData?.smilePlusPlans.currency}`, "error");
      sendError(e.message);
    }
  }, [planData?.smilePlusPlans.currency]);

  if (plansLoading || !profile?.smilePlusPlanInfos) {
    return <Spinner size="xl" />;
  }

  const handlePromoCodeSubmit = async (event: any) => {
    event.preventDefault();
    const inputValue = promoCodeInput.trim();

    if (inputValue === "") {
      setPromoCode("");
      setPromoCodeMessage("");
      return;
    }

    const promoDetails = findSmilePlusPromoDetails(inputValue);

    if (!promoDetails) {
      setPromoCode("");
      setPromoCodeMessage(t("smile:smilePlusSubscription_promoCodeApplyError"));
      return;
    }

    setPromoCode(inputValue);
    if (IS_HYBRIDGE) {
      if (inputValue === "levelup") {
        setPromoCodeMessage("12 months free");
        return;
      }
    }
    setPromoCodeMessage(t("smile:smilePlusSubscription_promoCodeApplySuccess", { discount: promoDetails.discount }));
  };

  return (
    <Box>
      <VStack spacing="10px" align="flex-start">
        {isTrialExpired && (
          <Text
            fontFamily={"Montserrat"}
            fontSize={"17px"}
            fontWeight={"bold"}
            lineHeight="125%"
            color={themeConsts.smile}
            mb={"40px"}
          >
            {t("smile:smilePlusSubscription_trialExpiredMessage")}
          </Text>
        )}
        {isPaymentFailed && (
          <Text
            fontFamily={"Montserrat"}
            fontSize={"17px"}
            fontWeight={"bold"}
            lineHeight="125%"
            color={themeConsts.smile}
            mb={"40px"}
          >
            {t("smile:smilePlusSubscription_subscriptionSuspendedMessage")}
          </Text>
        )}
        <SmileChangePlanSubtitle text={t(`smile:smilePlusHome_subscriptionCurrent`)} />
        {!currentPlanObject && <CopyText>{t("smile:pixSubscription_noPlanSelected")}</CopyText>}
        {!!currentPlanObject && (
          <SmileSubscriptionPlanView
            current={true}
            expiration={profile.smilePlusPlanInfos.nextBilling ?? "-"}
            plan={currentPlanObject}
            color={getColorFromPlan(currentPlanObject?.plan ?? SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_TRIAL)}
            priceFormatter={priceFormatter}
          />
        )}
      </VStack>
      <VStack spacing="10px" pb="20px" align="flex-start" pt="35px">
        <SmileChangePlanSubtitle text={t(`smile:smilePlusHome_subscriptionOther`)} />
      </VStack>
      <VStack spacing="16px" align="flex-start">
        <PlansFromCurrentPlan
          currentPlan={profile.smilePlusPlanInfos?.plan ?? SmilePlusSubscriptionPlanProductEnum.SMILE_PLUS_TRIAL}
          priceFormatter={priceFormatter}
          plans={planData?.smilePlusPlans?.plans ?? []}
          setChosenPlan={setChosenPlan}
        />
      </VStack>
      <Box>
        <Texts.NormalBold mt="35px">Promo code</Texts.NormalBold>
        <Box display={"flex"} flexDirection={{ base: "column", sm: "row" }} mt="8px">
          <Box
            as={"form"}
            flex={1}
            onSubmit={handlePromoCodeSubmit}
            fontFamily="OpenSans"
            fontSize={"12px"}
            fontWeight={"600"}
            lineHeight={"140%"}
          >
            <InputGroup>
              <FormControl>
                <Input
                  id="coupon"
                  variant="outline"
                  placeholder="Promo code"
                  h={"40px"}
                  bg={"rgba(255, 255, 255, 0.8)"}
                  border={"1px solid"}
                  borderColor={"#BBD1F2"}
                  borderRadius={"2px"}
                  boxShadow={"0px 20px 32px rgba(70, 25, 164, 0.05)"}
                  onChange={(event) => setPromoCodeInput(event.currentTarget.value)}
                />
              </FormControl>
              <InputRightElement>
                <Button type="submit" ml="-30px" variant="ghost" colorScheme="brandPink.200">
                  Apply
                </Button>
              </InputRightElement>
            </InputGroup>
          </Box>
          <Box
            flex={1}
            display={"flex"}
            alignItems={"center"}
            ml={{ base: "unset", sm: "25px" }}
            mt={{ base: "20px", sm: "unset" }}
          >
            <Text
              fontFamily={"Montserrat"}
              fontSize={"12px"}
              fontWeight={"bold"}
              lineHeight="125%"
              color={themeConsts.smile}
            >
              {promoCodeMessage}
            </Text>
          </Box>
        </Box>
      </Box>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          getProfile();
          setChosenPlan(SmilePlusSubscriptionPlanProductEnum.NONE);
          onClose();
        }}
        isCentered
      >
        <ModalOverlay>
          <ModalContent maxWidth="710px">
            <ModalCloseButton />
            <SmileChangePlanModal
              statePlan={profile?.smilePlusStatePlan ?? SubscriptionPlanStateEnum.NONE}
              closeModal={onClose}
              card={profile?.smilePlusPlanInfos?.card ?? null}
              coupon={promoCode}
              radioCardProps={{
                plan: planData?.smilePlusPlans?.plans?.find((plan) => plan.plan === chosenPlan),
                color: getColorFromPlan(chosenPlan),
                priceFormatter: priceFormatter,
              }}
            />
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </Box>
  );
};

export default SmileChangePlanSelectedView;

import { gql } from "@apollo/client";

const PLANS2 = gql`
  query {
    plans {
      country
      currency
      plans {
        plan
        price
        numberOfPhotosInPlan
      }
    }
  }
`;

const PREVIEW_CHANGE_PLAN2 = gql`
  query previewChangePlan2($toPlan: SubscriptionPlanProductEnum!) {
    previewChangePlan2(toPlan: $toPlan) {
      amountToPayNow
      planChangeDate
    }
  }
`;

const SMILE_PLUS_PLANS = gql`
  query {
    smilePlusPlans {
      country
      currency
      plans {
        plan
        price
      }
    }
  }
`;

const SMILE_PLUS_PREVIEW_CHANGE_PLANS = gql`
  query smilePlusPreviewChangePlan($toPlan: SmilePlusSubscriptionPlanProductEnum!, $coupon: String) {
    smilePlusPreviewChangePlan(toPlan: $toPlan, coupon: $coupon) {
      amountToPayNow
      planChangeDate
    }
  }
`;

export { PLANS2, PREVIEW_CHANGE_PLAN2, SMILE_PLUS_PLANS, SMILE_PLUS_PREVIEW_CHANGE_PLANS };
